@import url(https://fonts.googleapis.com/css2?family=MuseoModerno:wght@200;400&family=Oswald:wght@300;400&family=Stint+Ultra+Expanded&family=Teko:wght@300&display=swap);
body {
  margin: 0;
  font-family: "MuseoModerno", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Bungee Shade", Sans-serif;
}

html {
  background-color: white;
  color: aliceblue;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  color: black;
}

body::-webkit-scrollbar {
  display: none;
}

.App{
  text-align: center;
}

.head{
  background-color: #9CE2FF;
  font-size: 45px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.head img{
  margin: 5px 20px;
}


.head div{
  display: flex;
  align-items: center;
  margin: auto 30px;
}

.head button{
  padding: 10px;
  border-radius: 30px 35px 15px 15px;
  border: none;
  background-color: white;
  cursor: pointer;
  font-weight: 600;
}

.outer h1{
  margin-top: 0;
}

.outer{
  padding: 20px;
  background-image: url(/static/media/Meta-Nations-picture-final.636e5144.png);
  background-size: cover;
  background-position: center;
}

.head button:hover{
  color: skyblue;
}

.container div {
  background-color: #F3F4F6;
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #6B7280;
}

.one{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-right: 1px;
  width: 23%;
}

.two{
  width: 23%;
}

.three{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 1px;
  width: 23%;
}

.container p{
  margin: 0;
  font-size: 22px !important;
}

.container span{
  color: #2563EB;
  font-size: 45px !important;
}

.container {
  margin: 50px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  .container{
    display: flex;
    flex-direction: column;
  }
  .one{
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    margin-right: 0;
    margin-bottom: 1px;
    width: 100%;
  }
  .three{
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
    margin-left: 0;
    margin-top: 1px;
    width: 100%;
  }
  .two{
    width: 100%;
  }
}

.footer{
 background-color: #9CE2FF;
 color: black;
 padding:45px;
}

.footer img{
  width: 130px;
}

.footer div{
  display: flex;
  flex-direction: column;
  align-items: center;
}

a{
  color: #0b0430;
}
.head p{
  font-size: 15px;
  margin: 0;
  padding-bottom: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.head img{
  width: 70px;
  cursor: pointer;
}

.icons{
  display: flex;
  flex-direction: row!important;
  flex-wrap: wrap;
  justify-content: center;
}

.modal{
  display: flex;
  justify-content: space-between;
  align-items: left;
  flex-direction: column;
  background-color: snow;
  margin: auto;
  color: black;
}

.modal_head{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.max{
  align-items: center;
  cursor: pointer;
}


.inputbox{
  border: 2px solid black;
  display: flex;
  align-items: center;
  padding: 4px 15px;
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
}

.modal section{
  margin: 2px;
  margin-bottom: 24px;
}

section p {
  margin: 0;
}

.cross{
  cursor: pointer;
}

.modal input{
  color: black;
  border: none;
  margin: 0;
  background-color: transparent;
  outline: none;
}

.icon{
  padding: 10px;
  font-size: 40px!important;
}

@media screen and (max-width: 660px) {
  .icon{
    padding: 10px;
    font-size: 25px!important;
  }
  .mid_card{
    width: 90%!important;
  }
  .card{
    max-width: 340px!important;
    min-width: 270px!important;
  }
}

hr{
  border-top-width: 0px;
}

.card{
  max-width: 360px;
  min-width: 350px;
  padding: 20px;
  border-radius: 20px;
  margin: 20px;
  background-color: rgba(0, 10, 20, 0.8);
}

.card p{
  text-align: left;
}

.mid_card{
  width: 57%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.mid_card p{
  margin-bottom: 0;
}

.mid_card div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.middle{
  display: flex;
  margin: auto 100px;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  justify-content: space-around;

}

.inside{
  padding-top: 58vh;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a:hover{
  transform: scale(0.9);
}
a{
  transition: transform 350ms;
}

