@import url('https://fonts.googleapis.com/css2?family=MuseoModerno:wght@200;400&family=Oswald:wght@300;400&family=Stint+Ultra+Expanded&family=Teko:wght@300&display=swap');
body {
  margin: 0;
  font-family: "MuseoModerno", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Bungee Shade", Sans-serif;
}
